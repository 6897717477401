import "../../styles/buttons.css";
const HomeButton = ({ className }) => {
  const handleLaunchApp = () => {
    window.open("https://www.defi.blazpay.com", "_blank");
  };
  return (
    <div className={`${className}`}>
      <button
        onClick={handleLaunchApp}
        className={`ue_neon_btn font-redHat font-bold lg:text-[14px] xs:text-xs text-[9px] lg:px-9 sm:px-7 xs:px-5 px-4 lg:py-3 py-2 active:scale-[.96]`}
      >
        Launch App
      </button>
    </div>
  );
};

export default HomeButton;
