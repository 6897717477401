import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext(undefined);

export const ScrollProvider = ({ children }) => {
  const featuresRef = useRef(null);
  const homeRef = useRef(null);
  const scrollToFeatures = () => {
    if (featuresRef.current) {
      featuresRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <ScrollContext.Provider
      value={{ featuresRef, homeRef, scrollToFeatures, scrollToHome }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context;
};
