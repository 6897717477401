import { useState } from "react";
import blazlogo from "../assets/logo.svg";
import HomeButton from "../components/buttons/HomeButton";
import HamburgerMenu from "./Hamburger";
import { useScroll } from "../context/ScrollContext";

const navData = [
  {
    value: "home",
    link: "home",
    newTab: false,
  },
  {
    value: "about",
    link: "about",
    newTab: false,
  },
  {
    value: "blog",
    link: "https://medium.com/@blazpay",
    newTab: true,
  },
  {
    value: "rewards",
    link: "https://rewards.blazpay.com",
    newTab: true,
  },
];
const Logo = () => {
  const handleLogoClick = () => {
    window.open("https://www.blazpay.com", "_self");
  };
  return (
    <div className="sm:h-10 h-8  sm:w-32 xs:w-28 ss:w-20 w-28 -ml-5 xs:-ml-0 relative overflow-hidden group cursor-pointer  sm:gap-y-3 sm:pt-2">
      <img
        src={blazlogo}
        onClick={handleLogoClick}
        alt="Homepage Logo"
        className="active:scale-[.96] lg:scale-100 sm:scale-90 scale-[.75] cursor-pointer transition-all  ease delay-150 absolute top-2 group-hover:-top-8"
      />
      <img
        src={blazlogo}
        onClick={handleLogoClick}
        alt="Homepage Logo"
        className="active:scale-[.96] lg:scale-100 sm:scale-90 scale-[.75] cursor-pointer transition-all ease delay-150 absolute top-12 group-hover:top-2"
      />
    </div>
  );
};
const NavMenu = ({ navItems, isExpanded, setIsExpanded }) => {
  const { scrollToFeatures, scrollToHome } = useScroll();
  const handleItemClick = (link, newTab) => {
    if (link === "about") {
      scrollToFeatures();
      setIsExpanded(false);
      return;
    }
    if (link === "home") {
      scrollToHome();
      setIsExpanded(false);
      return;
    }
    if (link) {
      window.open(link, newTab ? "_blank" : "_self");
    }
  };

  return (
    <ul
      style={{
        perspective: "200px",
        backdropFilter: "blur(16px)",
      }}
      className={`${
        isExpanded ? "left-0" : "ss:left-0 left-[-100%]"
      } flex ss:relative fixed ss:w-fit w-full transition-all z-40 duration-[500ms] ease ss:flex-row flex-col ss:gap-y-0 gap-y-5 
        top-0 ss:h-fit h-64 items-center 
        justify-center lg:gap-x-12 md:gap-x-10 sm:gap-x-8 ss:gap-x-5
         lg:px-12 lg:px-10 xs:px-5 ss:px-4 lg:py-[10px] ss:py-2  bg-[#111]/60
         ss:rounded-full ss:border-[1.2px] border-r-[1.2px] border-[#ff5500]`}
    >
      {navItems?.map((item, index) => {
        return (
          <li
            role="button"
            onClick={() => handleItemClick(item.link, item.newTab)}
            className="font-redHat lg:text-base hover:text-[#ff5500]  xs:text-xs ss:text-[9px] text-[13px] transition-all duration-150 ease hover:scale-[.96] cursor-pointer font-semibold text-white/90 capitalize"
            key={index}
          >
            {item?.value}
          </li>
        );
      })}
    </ul>
  );
};
const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <header
      className={`fixed top-0  z-50 left-1/2 lg:py-6 py-4 sm:px-8 xs:px-4 pr-3  -translate-x-1/2 lg:container w-full 2xl:px-16 px-8 mx-auto
     flex items-center justify-between  
    `}
    >
      <Logo />
      <NavMenu
        navItems={navData}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <HomeButton className="ss:relative absolute  ss:right-0 right-12" />
      <HamburgerMenu
        className="ss:hidden block z-50"
        isExpanded={isExpanded}
        handleClick={() => setIsExpanded((x) => !x)}
      />
    </header>
  );
};

export default Navbar;
