import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import ErrorPage from "../pages/errorpage";
import { Suspense, lazy } from "react";
import LoadingPage from "../pages/loader";

const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );
};
const HomePage = Loader(lazy(() => import("../pages/homepage")));
const Routes = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);
};
export default Routes;
