import { RouterProvider } from "react-router-dom";
import Routes from "./routes";
import { ScrollProvider } from "./context/ScrollContext";
function App() {
  return (
    <ScrollProvider>
      <RouterProvider router={Routes()} />
    </ScrollProvider>
  );
}

export default App;
