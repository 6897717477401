import { useEffect, useState } from "react";
import "../styles/loader.css";
const LoadingPage = () => {
  const [currentMessage, setCurrentMessage] = useState("");
  const messages = [
    "Welcome to Blazpay",
    "AI Powered Defi..",
    "Future of Defi",
    "AI Powered Defi..",
  ];
  let messageIndex = 0;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const interval = setInterval(() => {
      setCurrentMessage(messages[messageIndex]);
      messageIndex = (messageIndex + 1) % messages.length;
    }, 400);

    return () => {
      clearInterval(interval);
      document.body.style.overflow = "";
    };
  }, [messages]);

  return (
    <section className="w-full fixed bg-black z-[10000] top-0 left-0 min-h-screen ">
      <div className="container-loader">
        <div className="loader"></div>
        <p
          id="message-1"
          className="loaderP text-white mt-5 ml-1 sm:tex-xl text-[13px]"
        >
          {currentMessage}
        </p>
      </div>
    </section>
  );
};

export default LoadingPage;
