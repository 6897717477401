import React from "react";

const HamburgerMenu = ({ className = "", isExpanded, handleClick }) => {
  return (
    <button
      className={`group relative h-6 w-6 ${className}`}
      aria-expanded={isExpanded}
      onClick={handleClick}
      type="button"
      style={{
        "--width": "1.3rem",
        "--thickness": "0.20rem",
        "--gap": "0.25rem",
        "--color": "#ff3503",
        "--duration": "300ms",
      }}
    >
      <span
        className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 translate-y-[calc(-150%-var(--gap))] transition-transform duration-[calc(var(--duration)*2/3)] 
        before:absolute before:right-0 before:h-full before:w-full before:rounded-full before:bg-[var(--color)] before:transition-[width] before:delay-[calc(var(--duration)*1/3)] before:duration-[calc(var(--duration)*2/3)] 
        group-aria-expanded:-translate-y-1/2 group-aria-expanded:-rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)] before:group-aria-expanded:w-[60%] before:group-aria-expanded:delay-0"
      ></span>
      <span
        className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 -translate-y-1/2 rounded-full bg-[var(--color)] transition-transform duration-[calc(var(--duration)*2/3)] 
        group-aria-expanded:rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)]"
      ></span>
      <span
        className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 translate-y-[calc(50%+var(--gap))] transition-transform duration-[calc(var(--duration)*2/3)] 
        before:absolute before:right-0 before:h-full before:w-[60%] before:rounded-full before:bg-[var(--color)] before:transition-[right] before:delay-[calc(var(--duration)*1/3)] before:duration-[calc(var(--duration)*2/3)] 
        group-aria-expanded:-translate-y-1/2 group-aria-expanded:-rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)] before:group-aria-expanded:right-[40%] before:group-aria-expanded:delay-0"
      ></span>
    </button>
  );
};

export default HamburgerMenu;
